import React, { ReactElement } from 'react';
import { Container } from 'react-bootstrap';
import { Layout } from '../components/common';
import ContentImageRow from '../components/common/ContentImageRow';
import BannerSection from '../components/common/contentSections/BannerSection';
import ContentHero from '../components/common/contentSections/ContentHero';
import HeadingSection from '../components/common/contentSections/HeadingSection';
import ImageContentRow from '../components/common/ImageContentRow';
import { ContentData } from '../utils/entities';

const CustomerDashboardContentData: ContentData[] = [
    {
        id: 1,
        title: `Easy use`,
        desc: `StackIAM provides a simplified view of the user dashboard which allows users to see all their information, change settings and track who has access to their information, including location and device specifications.`,
        imageUrl: `images/customer-dashboard-easy-use.svg`,
    },
    {
        id: 2,
        title: `User experience & manageability`,
        desc: `Customers deserve simple  and intuitive user experiences across all devices and that is what we provide with StackIAM.`,
        imageUrl: `images/customer-dashboard-user-experience.svg`,
    },
];

const CustomerDashboard = (): ReactElement => (
    <Layout>
        <Container>
            <ContentHero
                heading="Customer 
                Dashboard"
                desc="Customers can view their dashboard capturing who they are, settings, who has access and what kind of information they have access to."
                buttonText="Get Started"
                imageUrl="/images/customer-dashboard-hero.svg"
            />
            {/* <TypesSection
                heading="Employees Clocker Types"
                subheadingSection1="Web Clocker"
                subheadingSection2="Mobile Application Clocker"
                descSection1="Easily connect with partners that have their own IDP or access management deployment; federate using SAML or OIDC."
                descSection2="Connect your customer's LDAP or Active Directory; synchronize user accounts to Universal Directory."
                imageUrlSection1="images/workspace@3x.png"
                imageUrlSection2="images/mobile@3x.png"
            /> */}
            <HeadingSection
                heading="Features"
                subheading="Customer Dashboard"
            />
            {CustomerDashboardContentData.map((item) => {
                if (item.id % 2 === 0) {
                    return <ContentImageRow key={item.id} {...item} />;
                } else {
                    return <ImageContentRow key={item.id} {...item} />;
                }
            })}
            <BannerSection />
        </Container>
    </Layout>
);

export default CustomerDashboard;
